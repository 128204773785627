import React from 'react'
import './contactus.scss'

const ContactUs = () => {
  return (
    <div>
      <div className="contactus-container">
        <div className="note-contact" id="contact-us">
          <div className="note-main-head">Let's Talk About Your Project</div>
          <div className="note-text">
            India’s textile garments are renowned for their quality and
            craftsmanship, making them highly sought after globally. Our
            export-grade packaging ensures that every garment reaches its
            destination in perfect condition. We use advanced packaging
            materials that protect against moisture, dust, and handling damage.
            Coupled with a robust logistics network, we guarantee timely and
            efficient delivery to any part of the world.<br></br>
            <br /> Our logistics partners are experienced in handling
            international shipments, ensuring smooth customs clearance and
            delivery. From India to anywhere, we ensure that our garments not
            only meet but exceed global standards, reaching you in pristine
            condition.
          </div>
        </div>

        <div className="text-section">
          <div className="contact-text">Contact Us</div>
          <form className="contact-form">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="subject">Subject</label>
              <input type="text" id="subject" name="subject" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                rows="5"
                required
              ></textarea>
            </div>
            <button type="submit" className="cta-button">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
