import React, { useState } from "react";
import "./main.scss";
import tshirt from "../../assets/pexels-woven-swetter.jpg";
import men from "../../assets/men.png";
import garments from "../../assets/garments.png";
import sports from "../../assets/women.png";
import jacket from "../../assets/jacket.png";
import shirt from "../../assets/shirt.png";
import sourcing from "../../assets/sourcing.png";
import girl from "../../assets/girl.png";
import cloth3 from "../../assets/cloth3.png";
import boxer from "../../assets/boxer.png";
import sweater from "../../assets/sweater.png";
import wool from "../../assets/wool.png";
import suit from "../../assets/suit.png";

const products = [
  { id: 1, category: "Knit", title: "Knit Sweater", image: tshirt },
  { id: 2, category: "Polo", title: "Polo Shirt", image: men },
  { id: 3, category: "Tees", title: "Graphic Tee", image: garments },
  { id: 4, category: "Sports", title: "Sports Jersey", image: sports },
  { id: 5, category: "Hoodies", title: "Hoodie", image: jacket },
  { id: 6, category: "Shirts", title: "Casual Shirt", image: shirt },
  { id: 7, category: "Jackets", title: "Leather Jacket", image: sourcing },
  { id: 8, category: "Bottoms", title: "Jeans", image: girl },
  { id: 9, category: "Cotton", title: "Cotton Shirt", image: cloth3 },
  { id: 10, category: "Undergarments", title: "Boxers", image: boxer },
  { id: 11, category: "FlatKnits", title: "Flat Knit Sweater", image: sweater },
  { id: 12, category: "Shawls", title: "Wool Shawl", image: wool },
  { id: 13, category: "SpecialOccasions", title: "Suit", image: suit },
];

const categories = [
  {
    name: "Knit",
    subcategories: [
      "Polo",
      "Tees",
      "Sports",
      "Hoodies",
      "Shirts",
      "Jackets",
      "Bottoms",
    ],
  },
  {
    name: "Cotton",
    subcategories: ["Shirts", "Undergarments", "Bottoms"],
  },
  {
    name: "FlatKnits",
    subcategories: ["Shirts", "Shawls", "Bottoms", "SpecialOccasions"],
  },
];

const Ecommerce = () => {
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleCheckboxChange = (category) => {
    setSelectedCategories((prevSelected) => {
      if (prevSelected.includes(category)) {
        return prevSelected.filter((c) => c !== category);
      } else {
        return [...prevSelected, category];
      }
    });
  };

  const filteredProducts =
    selectedCategories.length === 0
      ? products
      : products.filter((product) =>
          selectedCategories.includes(product.category)
        );

  return (
    <div className="ecommerce-container">
      <aside className="sidebar">
        {categories.map((category) => (
          <div key={category.name}>
            <div style={{ fontSize: "25px" }}>{category.name}</div>
            {category.subcategories.map((subcategory) => (
              <div key={subcategory} className="subcategory">
                <input
                  type="checkbox"
                  id={subcategory}
                  value={subcategory}
                  onChange={() => handleCheckboxChange(subcategory)}
                />
                <label htmlFor={subcategory}>{subcategory}</label>
              </div>
            ))}
          </div>
        ))}
      </aside>
      <main className="filter-product">
        <div className="product-grid">
          {filteredProducts.map((product) => (
            <div key={product.id} className="product-card">
              <img src={product.image} alt={product.title} />
              <div>{product.title}</div>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
};

export default Ecommerce;
