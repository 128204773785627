import React from 'react'
import './whatwedo2.scss'
import collage3 from '../../assets/Collage3.jpg'
import aboutUs from '../../assets/About_Us.png'

const Whatwedo2 = () => {
  return (
    <div>
      <div className="wedo2-container">
        <div className="text-section">
          <div className="title">OUR VISION</div>
          <div className="text-sub-section">
            <div className="text">
              Deepwear's vision is to empower fashion designers and brands to
              have access to a trusted supply chain partner who can guide them,
              hand in hand, through the process of manufacturing high quality
              products in the most environmentally and socially sound manner.
            </div>
          </div>
          <div className="title" style={{ marginTop: '20px' }}>
            EXPERTISE, QUALITY & RELIABILITY
          </div>
          <div className="text-sub-section">
            <div className="text">
              Deepwear’s vision is to empower fashion designers and brands to
              have access to a trusted supply chain partner who can guide them,
              hand in hand, through the process of manufacturing high quality
              products in the most environmentally and socially sound manner.
            </div>
          </div>
          <div className="title" style={{ marginTop: '20px' }}>
            TRANSPARENCY & SUSTAINABILITY
          </div>
          <div className="text-sub-section">
            <div className="text">
              Deepwear’s vision is to empower fashion designers and brands to
              have access to a trusted supply chain partner who can guide them,
              hand in hand, through the process of manufacturing high quality
              products in the most environmentally and socially sound manner.
            </div>
          </div>
          <div className="title" style={{ marginTop: '20px' }}>
            MUTAL GROWTH & FLEXIBILITY
          </div>
          <div className="text-sub-section">
            <div className="text">
              Deepwear’s vision is to empower fashion designers and brands to
              have access to a trusted supply chain partner who can guide them,
              hand in hand, through the process of manufacturing high quality
              products in the most environmentally and socially sound manner.
            </div>
          </div>
        </div>

        <div className="image-cont">
          <img src={aboutUs} alt="" className="img-1" />
        </div>
      </div>
    </div>
  )
}

export default Whatwedo2
