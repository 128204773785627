import React from "react";
import "./aboutus.scss";
import ourTeam from "../../assets/Our_team.png";
import Logo from "../../assets/logo.png";

const AboutUs2 = () => {
  return (
    <div className="about-us-container">
      <div className="image-cont">
        <img src={ourTeam} alt="" className="img-1" />
      </div>
      <div className="text-section">
        <h1 className="title">
          <img src={Logo} alt="Logo" className="small-logo" />
          Stitch Sustainable <i>and Profitable</i>
        </h1>
        <h4 style={{ lineHeight: "normal", letterSpacing: "5px" }}>
          OUR VALUES
        </h4>
        <div className="text-sub-section">
          <div className="text">
            As we do not own the factories that make your products, we work with
            carefully selected suppliers, many of which have worked with us for
            many years. <br></br>
            <br></br> We work hard to ensure that your products are made with
            respect for the environment, in good working conditions, where
            workers’ rights are respected.
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs2;
