import React from 'react'
import './ourservice.scss'
import design from '../../assets/design.png'

const OurService = () => {
  return (
    <div className="ourservice-container">
      <h3 className="title-1">We Make It Easy</h3>
      <div className="text-sub-section">
        <div className="text">
          We recognized that many new and established brands, struggle to stay
          in the market due to the challenges faced in accessing the right
          manufacturers and sourcing the right textiles. <br /> Our Fashion
          business solution assists you with handling some of the stress of
          bringing your product to market. <br />
          <br /> Every product manager understands the pain of developing
          fashion products. You have to develop tools, source materials, find
          textile design agents, create patterns while staying true to the
          brand’s DNA, and staying on budget. We, as a clothing company, set up
          the design and development center to handle all of this. The result?
          Easier, faster developments and no wasted time or money for your
          brand.
          <div>
            <button className="cta-button">Subscribe Here</button>
          </div>
        </div>
        <img src={design} alt="" className="image-cont" />
      </div>
    </div>
  )
}

export default OurService
