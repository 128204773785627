import React from "react";
import "./manufacturing1.scss";
import sustainability_three from "../../assets/pexels-anete-lusina.jpg";
import FAQ1 from "./faq";

const Manufacturing1 = () => {
  return (
    <>
      {/* part1 */}
      <div className="manu-container">
        <div className="image-cont">
          <img
            src="https://deepwear.info/wp-content/uploads/Rectangle-49-5.jpg"
            alt=""
            className="img-0"
          />
        </div>

        <div className="text-section">
          <h1 className="title">Production Service</h1>
          <div className="text-sub-section ">
            <p>
              One of DEEPWEAR’s unique specialities is providing our clients
              with reliable, sustainable and ethical
              <strong>garment manufacturing and production services,</strong> as
              well as
              <span>
                <strong>
                  <a href="">manufacturers in jewelry</a>
                </strong>
              </span>
              and accessories.
            </p>
            <p>
              Strategically our offices are placed geographically in areas close
              to <strong>garment factories, apparel manufacturers,</strong> and
              suppliers, which means we have the ability to search multiple
              countries and get quotes from multiple factories for you at the
              same time.
            </p>

            <p>
              We have an experienced team who are passionate about bridging the
              gap between business leaders and the world’s best manufacturers.
              Our team is able to provide you with superb quality control and
              merchandising, to keep a close eye on{" "}
              <span>
                <strong>
                  <a href="">garment manufacturing and production,</a>
                </strong>
              </span>{" "}
              ensuring that all of the client’s requirements are captured and
              met. As we have offices in close proximity to factories &
              suppliers we are present on the ground before, during and after
              production, ensuring all of the client’s requirements are met.
            </p>
            <div className="btn-div">
              <div className="custom-btn btn1">
                <span> Sign up for Expert Advice & Industry Insights</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* //part 2 */}
      <div className="manu-container1">
        <div className="text-section1">
          <h1 className="title1">Dedicated production manager</h1>

          <div className="text-sub-section1">
            <div className="text1">
              Each client works with a personal project manager who is your
              point of contact throughout the entire garment manufacturing
              process. <br></br>
              <br></br> Our project managers provide guidance and support
              through all stages of sustainable garment manufacturing and are
              dedicated to your success. We will track and communicate each step
              of the production process and strictly keep to agreed timelines
            </div>
          </div>
        </div>
        <div className="image-cont1">
          <img
            src="https://deepwear.info/wp-content/uploads/17-1.jpg"
            alt=""
            className="img-1"
          />
        </div>
      </div>
      {/* part3 */}
      <div className="manu-container3">
        <h1 className="title3">
          We’re your global sourcing and supply chain management partner. We
          help businesses take the hassle out of finding and shipping fashion
          and clothing products from overseas.
        </h1>
      </div>
      {/* //part 4 */}
      <div className="manu-container4">
        <div className="image-cont4">
          <img
            src="https://deepwear.info/wp-content/uploads/14.jpg"
            alt=""
            className="img-1"
          />
          <div className="manu-sub-section">
            <h1>SEAMLESS FASHION MANUFACTURERS</h1>
            <p>
              The range of services we offer to assist you in bringing your
              creations to life include
            </p>
            <ul className="list-item">
              <li>
                <strong>Garment production</strong> planning
              </li>
              <li>Scheduling</li>
              <li>Quoting</li>
              <li>Advice</li>
              <li>Sourcing of fabrics and accessories, </li>
              <li>
                Technical Design Files: pattern making, sampling, digitizing,
                grading, marker making and cutting,{" "}
              </li>
            </ul>
          </div>
        </div>
        <div className="image-cont4">
          <img
            src="https://deepwear.info/wp-content/uploads/13.jpg"
            alt=""
            className="img-1"
          />
          <div className="manu-sub-section">
            <h1>
              <br />
            </h1>
            <p>
              <br />
            </p>
            <ul className="list-item">
              <li>manufacturing</li>
              <li>quality control, </li>
              <li>fitting service, </li>
              <li>Trims and labelling, </li>
              <li>packing, and logistics. </li>
            </ul>
            <p>
              Everything is managed by experienced ethical fashion and
              sustainable manufacturers in the industry.
            </p>
          </div>
        </div>
      </div>
      {/* prtrt5  */}
      <div className="manu-container5">
        <div className="sub1-section">
          <div className="sub1-title">
            <div className="sub1-title1">
              <h1>Sampling</h1>
              <h1>
                Sampling reduces error and risk, which saves money in the long
                run. We recommend this to all our clients.
              </h1>
            </div>
            <div className="btn-div btn-div2">
              <div className="custom-btn btn1">
                <span> book a call</span>
              </div>
            </div>
          </div>
        </div>

        <div className="sub2-section">
          <aside className="sub2-aside">
            <h1>Prototype Sampling</h1>
            <p>
              Prototype sampling provides you with a tangible representation of
              your idea to see how your finished garment might look. It is
              common to have revisions and changes at this stage. To create a
              pro­totype, we would need a sample or a technical file.
            </p>
            <div>
              <img
                src="https://deepwear.info/wp-content/uploads/Rectangle-96.jpg"
                alt=""
              />
            </div>
          </aside>
          <aside>
            <h1>Sales Samples</h1>
            <p>
              Sales samples allow you to bring your ideas to buyers without
              committing to costly inventory. Sales samples give the buyer a
              full understanding of the available products in your line.
            </p>
            <div>
              <img
                src="https://deepwear.info/wp-content/uploads/15-1.jpg"
                alt=""
              />
            </div>
          </aside>
          <aside>
            <h1>Pre-Production Samples</h1>
            <p>
              Pre-production samples showcase exactly what the completed garment
              will look like in all sizes and styles, including trims, fabrics,
              and embellishments. We can assist in organizing this critical step
              that allows our clients to verify the sizing and grading before
              going into full production.
            </p>
            <div>
              <img
                src="https://deepwear.info/wp-content/uploads/16.jpg"
                alt=""
              />
            </div>
          </aside>
        </div>
      </div>

      {/* prtrt6  */}
      <div className="manu-container">
        <div className="image-cont">
          <img
            src="https://deepwear.info/wp-content/uploads/Rectangle-99-2.jpg"
            alt=""
            className="img-0"
          />
        </div>

        <div className="text-section">
          <h1 className="title">
            MEET OUR FAIR TRADE CLOTHING <br /> MANUFACTURERS
          </h1>
          <div className="text-sub-section text-sub-section-part8 ">
            <p>
              Every <strong>apparel manufacturer</strong> has a story. We seek
              the best ethical, fair trade and sustainable garment manufacturers
              from around the world to make your products.
            </p>
            <p>
              {" "}
              <strong>#KnowYourManufacturer</strong>{" "}
            </p>

            <div className="btn-div btn-div2">
              <div className="custom-btn btn1">
                <span> join now</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* prtrt7  */}
      <div className="manu-container1">
        <div className="text-section1">
          <h1 className="title1">Technical Detail</h1>

          <div className="text-sub-section1">
            <div className="text1">
              A tech-pack is required to outline the specifications necessary to
              be provided to a <strong>garment factory</strong> before a product
              is created. This document will detail all measurements, quantity,
              colors, sizing, grading, fabric, embroidery, printing, packing
              instruction, etc. Having a complete tech pack is beneficial in
              streamlining the process and avoiding unnecessary contact with the
              designer to validate small details in{" "}
              <strong> clothing production.</strong> <br /> <br /> We allow you
              to have your technical specifications in-hand whether you’re
              manufacturing at our facilities or with another organization. If
              you’re interested in learning more send us a request below and
              we’ll get started!
            </div>
          </div>
        </div>
        <div className="image-cont1">
          <img
            src="https://deepwear.info/wp-content/uploads/Rectangle-100.jpg"
            alt=""
            className="img-1"
          />
        </div>
      </div>

      {/* part8  */}
      <div className="manu-container3">
        <h1 className="title3">
          Deepwear offers the creation of the tech pack as a service to our
          clients.
        </h1>
      </div>

      {/* prtrt9  */}
      <div className="manu-container">
        <div className="image-cont">
          <img
            src="https://deepwear.info/wp-content/uploads/05-1.jpg"
            alt=""
            className="img-0"
          />
        </div>

        <div className="text-section part10-sec">
          <div className="image-cont-1">
            <img
              src="https://deepwear.info/wp-content/uploads/06.jpg"
              alt=""
              className="img-0"
            />
          </div>
          <div className="text-sub-section1">
            <p>
              <strong>Pattern making</strong>
            </p>
            <p>
              This step in <strong>garment manufacturing</strong> not only takes
              skill but is very important in creating a tangible representation
              of your vision. Today, we can digitize this technical request, but
              also typically create paper patterns too.
            </p>
            <p>
              <strong>Sizing/Grading/Marking</strong>
            </p>
            <p>
              Get the specifications right for your target customer. The cost is
              based on the number of pattern pieces in the style. We strive to
              deliver a garment that fits perfectly, just as you.
            </p>
          </div>
        </div>
      </div>
      {/* prtrt10  */}

      <FAQ1 />
    </>
  );
};

export default Manufacturing1;
