import React from 'react'
import PageHeader from '../../components/pageheader'
import ContactUs from '../../components/contact-us/contactus'
import Footer from '../../components/footer/footer'
import AboutUs2 from '../../components/about-us/aboutus2'
import Whatwedo2 from '../../components/whatwedo2/whatwedo2'
import OurService from '../../components/our-services/ourservice'

const AboutUs = () => {
  return (
    <div>
      <PageHeader currentPage={"About us"} banner={"banner-1 banner-2"} />
      <AboutUs2 />
      <Whatwedo2 />
      <OurService />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default AboutUs
